// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import TruthEditor from 'components/editor/TruthEditor'

function init_truth_editor(props){
  ReactDOM.render(
    <TruthEditor {...props} />,
    document.getElementById("truth_editor_root")
  );
}
document.addEventListener('DOMContentLoaded', () => {

  // if (window.location.pathname.startsWith("/statements")){
  //   if(window.location.pathname == '/statements/new'){
  //     const props = {
  //       model: 'Statement',
  //       greeting: "Hello Jesus!! 주신 소명 이루소서!"
  //     }
  //     return init_truth_editor(props);
  //   }
  //
  //   const match = /^\/statements\/(\d+)\/edit$/.exec(window.location.pathname);
  //   if (match) {
  //     const props = {
  //       model: 'Statement',
  //       id: match[1],
  //       greeting: "Hello Jesus!! 주신 소명 이루소서!"
  //     }
  //     return init_truth_editor(props);
  //   }
  // }

})
